import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, ExpandingTextarea } from '../../components';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import css from './FieldTextInput.module.css';

const CONTENT_MAX_LENGTH = 5000;

class FieldTextWithDateInputComponent extends Component {
  render() {
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      endAdornment,
      ...rest
    } = this.props;

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const isTextarea = input.type === 'textarea';
    const errorText = customErrorText || error;
    const hasError = !!customErrorText || !!(touched && invalid && error);
    const fieldMeta = { touched: hasError, error: errorText };

    const inputClasses = classNames(inputRootClass || css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
      [css.textarea]: isTextarea,
    });

    const inputProps = isTextarea
      ? {
          className: inputClasses,
          id,
          rows: 1,
          maxLength: CONTENT_MAX_LENGTH,
          ...input,
          ...rest,
        }
      : {
          className: inputClasses,
          id,
          ...input,
          ...rest,
        };

    const combinedRootClasses = classNames(rootClassName || css.root, className);
    const inputWrapperClasses = classNames(css.inputWrapper, {
      // Additional conditional classes for inputWrapper can be added here
    });

    return (
      <div className={combinedRootClasses}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <div className={inputWrapperClasses}>
          {isTextarea ? <ExpandingTextarea {...inputProps} /> : <input {...inputProps} onClick={this.props.onClick} ref={inputRef} className={css.inputField} />}
          {endAdornment && <div className={css.endAdornment}>{endAdornment}</div>}
        </div>
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldTextWithDateInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldTextWithDateInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  customErrorText: string,
  id: string,
  label: string,
  isUncontrolled: bool,
  inputRef: object,
  input: shape({
    onChange: func.isRequired,
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextWithDateInput extends Component {
  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextWithDateInputComponent} {...this.props} />;
  }
}

export default FieldTextWithDateInput;
