import React, { useState } from 'react';
import { PrimaryButton, Button, Modal } from '../../../components';
import css from './MakeClaimModal.module.css';

const MakeClaimModal = props => {
  const { onMakeClaim, currentTransaction } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [claimValue, setClaimValue] = useState(null);
  const [detailsValue, setDetailsValue] = useState(null);

  const handlePriceChange = e => {
    const value = e.target.value;
    function containsOnlyNumbers(str) {
      return /^\d+$/.test(str);
    }
    if (containsOnlyNumbers(value)) {
      setClaimValue(value);
    }
  };

  const claimButtonLabel = claimValue ? `Send claim for $${claimValue}` : 'Send claim';

  const handleSendClaim = e => {
    e.preventDefault();
    if (claimValue && detailsValue) {
      return onMakeClaim(currentTransaction?.id?.uuid, claimValue, detailsValue);
    }
  };
  return (
    <div className={css.mainWrapper}>
      <Button
        onClick={e => {
          e.preventDefault();
          setModalOpen(true);
        }}
        className={css.openModalButton}
      >
        CLAIM
      </Button>
      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.modalContentWrapper}>
          <p className={css.inputLabel}>Amount estimated is USD</p>
          <input
            className={css.input}
            type="text"
            placeholder="100"
            value={claimValue}
            min={1}
            onChange={handlePriceChange}
          />

          <p className={css.inputLabel}>Details</p>
          <input
            className={css.input}
            type="text"
            placeholder="cleaning fee"
            value={detailsValue}
            onChange={e => setDetailsValue(e.target.value)}
          />

          <PrimaryButton onClick={handleSendClaim} disabled={!claimValue || !detailsValue}>
            {claimButtonLabel}
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

export default MakeClaimModal;
