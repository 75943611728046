import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import FieldTextWithDateInput from '../../components/FieldTextInput/FieldTextWithDateInput';

//import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import Popover from '@mui/material/Popover';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Field } from 'react-final-form';

import css from './SignupForm.module.css';
import InternationalPhoneNumberInput from '../../components/InternationalPhoneNumberInput/InternationalPhoneNumberInput';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {

  console.log("SignupFormComponent rendered");

  
  const [selectedDate, setSelectedDate] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const dateInputRef = useRef(null);
  const [popoverAlreadyOpened, setPopoverAlreadyOpened] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handleClose = () => {
    setIsPopoverOpen(false);
    //setPopoverAlreadyOpened(false);
  }

  useEffect(() => {
    console.log('popoverAlreadyOpened updated:', popoverAlreadyOpened);
  }, [popoverAlreadyOpened]);
  

  const handleDateClick = (e) => {
    // writh typeof e.target
    if (e.target.type === 'date' && popoverAlreadyOpened) {
      return;
    }
    console.log(typeof(e.target));
    if (popoverAlreadyOpened)
    {
      return;
    }
    if (!isPopoverOpen) {
      setIsPopoverOpen(true);
      setPopoverAlreadyOpened(true);
      console.log(popoverAlreadyOpened);
    }
  };

  const handleIconDateClick = () => {
    if (!isPopoverOpen) {
      setIsPopoverOpen(true);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FinalForm
        {...props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            intl,
            onOpenTermsOfService,
            values,
          } = fieldRenderProps;

          const emailLabel = intl.formatMessage({
            id: 'SignupForm.emailLabel',
          });
          const emailPlaceholder = intl.formatMessage({
            id: 'SignupForm.emailPlaceholder',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'SignupForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const birthDayRequired = validators.required('Birth day is required');
          
          const passwordLabel = intl.formatMessage({
            id: 'SignupForm.passwordLabel',
          });
          const passwordPlaceholder = intl.formatMessage({
            id: 'SignupForm.passwordPlaceholder',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'SignupForm.passwordRequired',
          });
          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );
          const passwordMaxLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.passwordTooLong',
            },
            {
              maxLength: validators.PASSWORD_MAX_LENGTH,
            }
          );
          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );
          const passwordMaxLength = validators.maxLength(
            passwordMaxLengthMessage,
            validators.PASSWORD_MAX_LENGTH
          );
          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
          const passwordValidators = validators.composeValidators(
            passwordRequired,
            passwordMinLength,
            passwordMaxLength
          );

          const firstNameLabel = intl.formatMessage({
            id: 'SignupForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'SignupForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          const lastNameLabel = intl.formatMessage({
            id: 'SignupForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'SignupForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress || !values.phoneNumber;

          const handleTermsKeyUp = e => {
            if (e.keyCode === KEY_CODE_ENTER) {
              onOpenTermsOfService();
            }
          };

          const termsLink = (
            <span
              className={css.termsLink}
              onClick={onOpenTermsOfService}
              role="button"
              tabIndex="0"
              onKeyUp={handleTermsKeyUp}
            >
              <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
            </span>
          );

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
              
              
        
        

                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />

                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
                <InternationalPhoneNumberInput
                  label={'Phone number'}
                  name={'phoneNumber'}
                  id={'phoneNumber'}
                />

{/* <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        {isTextarea ? <ExpandingTextarea {...inputProps} /> : <input {...inputProps} />}
        <ValidationError fieldMeta={fieldMeta} />
      </div> */}


<br/>
        
        <Field
          name="dateOfBirth"
          render={({ input, meta }) => (
            // <TextField
            //   {...input}
            //   type="date"
            //   inputRef={dateInputRef}
            //   id={formId ? `${formId}.dateOfBirth` : 'dateOfBirth'}
            //   variant="outlined"
            //   fullWidth
            //   onClick={handleDateClick}
            //   InputProps={{
            //     endAdornment: (
            //       <IconButton onClick={handleIconDateClick}>
            //         <InfoIcon />
            //       </IconButton>
            //     ),
            //   }}
            //   error={meta.touched && meta.error}
            //   helperText={meta.touched && meta.error ? meta.error : null}
            // />
            <FieldTextWithDateInput
              name="dateOfBirth"
              type="date"
              id={formId ? `${formId}.dateOfBirth` : 'dateOfBirth'}
              label="Date of Birth"
              onClick={handleDateClick}
              inputRef={dateInputRef}
              validate={birthDayRequired}
              endAdornment={(
                <IconButton onClick={handleIconDateClick}>
                  <InfoIcon />
                </IconButton>
              )}
            />

  )}
/>

        <Popover
          open={isPopoverOpen}
          anchorEl={dateInputRef.current}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={css.popup}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>

            <span>
              Renters and drivers <b>must be over 25 years old.</b><br/>
              Renters will be required to verify their identity
              with a valid U.S. driver's license before booking. 
              <br/>
              Vehicle owners will be required to verify their identity before listing.
            </span>
          </div>
        </Popover>


                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />

                <div className={css.bottomWrapper}>
                  <p className={css.bottomWrapperText}>
                    <span className={css.termsText}>
                      <FormattedMessage
                        id="SignupForm.termsAndConditionsAcceptText"
                        values={{ termsLink }}
                      />
                    </span>
                  </p>
                  <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                    <FormattedMessage id="SignupForm.signUp" />
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          );
        }}
      />
    </LocalizationProvider>
  );
};

SignupFormComponent.defaultProps = {
  inProgress: false,
};

SignupFormComponent.propTypes = {
  inProgress: PropTypes.bool,
  onOpenTermsOfService: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SignupFormComponent);
