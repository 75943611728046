import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, FieldSelect, FieldTextInput, Form } from '../../components';

import css from './EditListingFeaturesForm.module.css';
import { composeValidators, required } from '../../util/validators';
import UpsellingItemsField from './UpsellingItemsField/UpsellingItemsField';
import { compose } from 'redux';
import { includedOptions } from '../../util/general';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
        form: {
          mutators: { push },
        },
        values,
        intl,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const hitchSizeOptions = [
        {
          key: '1-1/4"',
          label: '1-1/4"',
        },
        {
          key: '2"',
          label: '2"',
        },
        {
          key: '2-1/2"',
          label: '2-1/2"',
        },
        {
          key: '3"',
          label: '3"',
        },
      ];

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="towWeight"
            name="towWeight"
            className={css.textField}
            type="number"
            label={'Total tow weight in lbs. (including trailer)'}
            placeholder={
              'This will give the renter an idea of what vehicle they need to tow with...'
            }
            min={1}
            max={999999999999}
            validate={required('You need to complete this field')}
          />

          <FieldTextInput
            id="availableSeats"
            name="availableSeats"
            className={css.textField}
            type="number"
            label={'Available Seats'}
            placeholder={'4'}
            min={1}
            max={6}
            validate={required('You need to complete this field')}
          />

          <FieldSelect
            className={css.category}
            name={'hitchSize'}
            id={'hitchSize'}
            label={'Trailer hitch size'}
            validate={required('You need to select an option')}
          >
            <option disabled value="">
              {'Choose your hitch size'}
            </option>
            {hitchSizeOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <br />
          <FieldCheckboxGroup
            className={css.features}
            id={'included'}
            name={'included'}
            label={'Select all that are included in the rental price:'}
            twoColumns={true}
            options={includedOptions}
          />

          <h2>
            Add ons are "extras" the renter can select at booking for an additional price. A few
            examples are helmets, goggles or even a guided tour if you chose not to include those
            items as features. This can be set as fixed or daily price.
          </h2>

          <UpsellingItemsField push={push} form={form} values={values} intl={intl} />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
