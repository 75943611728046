import React, { useState } from 'react';
import {
  PrimaryButton,
  Button,
  Modal,
  FieldDateRangeInput,
  FieldCurrencyInput,
  FieldTextInput,
  FieldCheckboxGroup,
} from '../../../components';
import css from './MakeOfferModal.module.css';

import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import config from '../../../config';
import { FormattedMessage } from 'react-intl';
import EstimatedBreakdownMaybe from '../../../forms/BookingDatesForm/EstimatedBreakdownMaybe';
import {
  TRANSITION_MAKE_OFFER,
  TRANSITION_MAKE_OFFER_AFTER_REQUEST,
} from '../../../util/transaction';
import { addonsTypeOptions } from '../../../util/general';
import { getAddonsOptions } from '../../../forms/BookingDatesForm/utils';

const identity = v => v;

const MakeOfferModal = props => {
  const {
    onMakeOffer,
    onFetchQuoteLineItems,
    quoteLineItems,
    listing,
    timeSlots,
    fetchQuoteLineItemsInProgress,
    fetchQuoteLineItemsError,
    isPreauthorized,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);

  const onSubmit = values => {
    const { price, bookingDates, addons } = values;
    const { startDate, endDate } = bookingDates || {};
    const priceAmount = price?.amount ? price?.amount / 100 : null;

    const submitData = {
      priceAmount,
      startDate: startDate.toString(),
      endDate: endDate.toString(),
      transition: isPreauthorized ? TRANSITION_MAKE_OFFER_AFTER_REQUEST : TRANSITION_MAKE_OFFER,
      addons,
    };

    return onMakeOffer(submitData);
  };

  const onChange = formValues => {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};

    const { price, deliveryFee, addons } = formValues.values;
    const priceAmount = price?.amount ? price?.amount / 100 : null;
    const listingId = listing?.id;
    const isOwnListing = false;
    if (startDate && endDate && !fetchQuoteLineItemsInProgress && priceAmount) {
      onFetchQuoteLineItems({
        bookingData: {
          startDate,
          endDate,
          offerAmount: priceAmount,
          deliveryFee: deliveryFee?.amount,
          addons,
        },
        listingId,
        isOwnListing,
      });
    }
  };

  return (
    <div className={css.mainWrapper}>
      <PrimaryButton
        type="button"
        onClick={e => {
          e.preventDefault();
          setModalOpen(true);
        }}
        className={css.offerButton}
      >
        Send a quote
      </PrimaryButton>
      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onManageDisableScrolling={() => {}}
        wide={true}
      >
        <h2 className={css.title}>Send a quote</h2>

        <FinalForm
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          render={fieldRenderProps => {
            const {
              handleSubmit,
              invalid,
              pristine,
              submitting,
              formName,
              emailSend,
              values,
            } = fieldRenderProps;

            const submitDisabled = invalid || pristine || submitting;

            const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};

            const bookingData =
              startDate && endDate
                ? {
                    unitType: config.bookingUnitType,
                    startDate,
                    endDate,
                  }
                : null;

            const showEstimatedBreakdown =
              bookingData &&
              quoteLineItems &&
              !fetchQuoteLineItemsInProgress &&
              !fetchQuoteLineItemsError;

            const bookingInfoMaybe = showEstimatedBreakdown ? (
              <div className={css.priceBreakdownContainer}>
                <h3 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
                </h3>
                <EstimatedBreakdownMaybe
                  bookingData={bookingData}
                  lineItems={quoteLineItems}
                  hideDeposit={true}
                />
              </div>
            ) : (
              <div className={css.breakdownPlaceholder}>
                <p>
                  Breakdown waiting for the pick up / drop off dates
                  <br />
                  and the price per day
                </p>
              </div>
            );
            const addonsOptions = getAddonsOptions(listing);

            return (
              <div className={css.modalContentWrapper}>
                <div className={css.modalLeft}>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    <FormSpy
                      // onChange={onChange}
                      subscription={{ values: true }}
                      onChange={onChange}
                    />

                    <FieldDateRangeInput
                      className={css.datesField}
                      name="bookingDates"
                      unitType={config.bookingUnitType}
                      startDateId={`bookingStartDate`}
                      startDateLabel={'When will they pick up'}
                      startDatePlaceholderText={'Select date..'}
                      endDateId={`bookingEndDate`}
                      endDateLabel={'and drop off?'}
                      endDatePlaceholderText={'Select date..'}
                      focusedInput={focusedInput}
                      onFocusedInputChange={i => {
                        setFocusedInput(i);
                      }}
                      format={identity}
                      timeSlots={timeSlots}
                      useMobileMargins
                      disabled={false}
                    />

                    <FieldCurrencyInput
                      id="price"
                      name="price"
                      className={css.field}
                      label={'Price per day?'}
                      placeholder={'$100'}
                      currencyConfig={config.currencyConfig}
                    />
                    <FieldCheckboxGroup
                      label={'Add ons'}
                      className={css.checkboxGroup}
                      id={'addons'}
                      name={'addons'}
                      options={addonsOptions}
                    />
                    {/* <FieldTextInput
                      id="addons"
                      name="addons"
                      className={css.field}
                      type="text"
                      label={'Any add ons?'}
                      placeholder={'1'}
                    /> */}

                    {/* <FieldCurrencyInput
                      id="cleaningFee"
                      name="cleaningFee"
                      className={css.field}
                      label={'Cleaning Fee (if brought back dirty).'}
                      placeholder={'$30'}
                      currencyConfig={config.currencyConfig}
                    />

                    <FieldCurrencyInput
                      id="deliveryFee"
                      name="deliveryFee"
                      className={css.field}
                      label={'Delivery Fee'}
                      placeholder={'$40'}
                      currencyConfig={config.currencyConfig}
                    /> */}

                    <Button className={css.submit} type="submit" disabled={submitDisabled}>
                      Send quote
                    </Button>
                  </form>
                </div>

                <div className={css.modalRight}>{bookingInfoMaybe}</div>
              </div>
            );
          }}
        />

        {/* <PrimaryButton
            onClick={handleSendOffer}
            className={css.sendOfferButton}
            disabled={!priceValue}
          >
            {offerButtonLabel}
          </PrimaryButton> */}
      </Modal>
    </div>
  );
};

export default MakeOfferModal;
