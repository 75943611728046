import React, { memo, useState } from 'react';
import css from './InternationalPhoneNumberInput.module.css';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input/input';
import { useEffect } from 'react';

const PhoneField = memo(({ input: { onChange, value }, label, ...rest }) => {
  const [error, setError] = useState(false);
  return (
    <div
      className={css.wrapper}
      onFocus={() => {
        setError(false);
      }}
      onBlur={() => {
        if (!value) {
          setError(true);
        } else {
          setError(false);
        }
      }}
    >
      <p className={css.label}>{label}</p>
      <PhoneInput
        country="US"
        placeholder="Enter phone number"
        value={value}
        onChange={p => onChange(p)}
      />

      {error && <p className={css.error}>You need to add a phone number</p>}
    </div>
  );
});

const InternationalPhoneNumberInput = props => {
  const { name, id, label } = props;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('react-phone-number-input/style.css');
    }
  }, []);

  return <Field name={name} label={label} component={PhoneField} />;
};

export default InternationalPhoneNumberInput;
