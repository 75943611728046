import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-salt-lake',
    predictionPlace: {
      address: 'Salt Lake City, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.921846, -111.557539),
        new LatLng(40.591352, -112.195979)
      ),
    },
  },
  {
    id: 'default-las-vegas',
    predictionPlace: {
      address: 'Las Vegas, Nevada',
      bounds: new LatLngBounds(
        new LatLng(36.853605, -114.66724),
        new LatLng(35.836893, -115.895753)
      ),
    },
  },
  {
    id: 'default-american-fork',
    predictionPlace: {
      address: 'American Fork, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.411649, -111.76298),
        new LatLng(40.334308, -111.836356)
      ),
    },
  },
  {
    id: 'default-cedar-fort',
    predictionPlace: {
      address: 'Cedar Fort, Utah',
      bounds: new LatLngBounds(
        new LatLng(40.392127, -112.058882),
        new LatLng(40.298056, -112.15507)
      ),
    },
  },
  {
    id: 'default-st-george',
    predictionPlace: {
      address: 'St. George, Utah',
      bounds: new LatLngBounds(
        new LatLng(37.346265, -113.457666),
        new LatLng(36.999982, -113.776735)
      ),
    },
  },
];
export default defaultLocations;
