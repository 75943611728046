import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_SALES_TAX, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidSalesTax = salesTaxLineItem => {
  return (
    salesTaxLineItem &&
    salesTaxLineItem.lineTotal instanceof Money &&
    salesTaxLineItem.lineTotal.amount >= 0
  );
};

const LineItemSalesTaxMaybe = props => {
  const { transaction, isCustomer, intl } = props;

  const salesTaxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SALES_TAX && !item.reversal
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  let salesTaxItem = null;

  if (isCustomer && salesTaxLineItem) {
    if (!isValidSalesTax(salesTaxLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', salesTaxLineItem);
      throw new Error('Sales tax should be present and the value should be zero or positive');
    }

    const salesTax = salesTaxLineItem.lineTotal;
    const formattedSalesTax = salesTax ? formatMoney(intl, salesTax) : null;

    salesTaxItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.salesTax" />
        </span>
        <span className={css.itemValue}>{formattedSalesTax}</span>
      </div>
    );
  }

  return salesTaxItem;
};

LineItemSalesTaxMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSalesTaxMaybe;
