import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedDate, FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsWaitingForCustomerSignature,
  txIsWaitingForProviderSignature,
  TRANSITION_MAKE_OFFER,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_ACCEPT,
  TRANSITION_REQUEST_CLAIM_BY_PROVIDER,
  TRANSITION_ACCEPT_CLAIM_BY_OPERATOR,
  TRANSITION_DECLINE_CLAIM_BY_OPERATOR,
  TRANSITION_SIGNED_BY_PROVIDER,
  TRANSITION_MAKE_OFFER_AFTER_REQUEST,
  TRANSITION_ENQUIRE_WITH_ACCEPTED_OFFER,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  PrimaryButton,
  Modal,
  SecondaryButton,
  Button,
} from '../../components';
//import UploadIcon from '@mui/icons-material/Upload';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import ArticleIcon from '@mui/icons-material/Article';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_WAITING_FOR_CUSTOMER_SIGNATURE,
  HEADING_WAITING_FOR_PROVIDER_SIGNATURE,
  HEADING_SIGNED_BY_PROVIDER,
} from './PanelHeading';
import { post } from '../../util/api';
import MakeOfferModal from './MakeOfferModal/MakeOfferModal';
import css from './TransactionPanel.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import MakeClaimModal from './MakeClaimModal/MakeClaimModal';
import { sendSmsNotification } from '../../util/notifications';

const { UUID, Money } = sdkTypes;

const showClaim = process.env.REACT_APP_TOOGLE_SHOW_CLAIM === 'true';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

// Helper function to get the other user info
const getOtherUser = (currentUser, currentProvider, currentCustomer) => {
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    return currentProvider;
  } else if (currentUserIsProvider) {
    return currentCustomer;
  }
};
export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      signatureModalOpen: false,
      contractSent: false,
      loading: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.setSignatureModalOpen = this.setSignatureModalOpen.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  setSignatureModalOpen(value) {
    this.setState({
      signatureModalOpen: value,
    });
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, transactionRole, currentUser } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(resp => {
        const otherUser = getOtherUser(currentUser, transaction?.provider, transaction?.customer);
        return sendSmsNotification({
          sender: currentUser,
          receiver: otherUser,
          transactionId: transaction?.id?.uuid,
          type: 'NEW_MESSAGE',
          receiverRole: transactionRole === 'customer' ? 'provider' : 'customer',
        })
          .then(cResp => {
            return resp;
          })
          .catch(e => {
            return resp;
          });
      })
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onSignContract,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,

      onFetchQuoteLineItems,
      quoteLineItems,

      fetchQuoteLineItemsInProgress,
      fetchQuoteLineItemsError,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onCancelSale,

      onMakeOffer,
      onDeclineOffer,
      onAcceptOffer,
      onMakeClaim,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const protectedData = currentTransaction?.attributes?.protectedData || {};
    const offerAmount = protectedData?.offerAmount && Number(protectedData?.offerAmount);
    const offerStartDate = protectedData?.startDate && new Date(protectedData?.startDate);
    const offerEndDate = protectedData?.endDate && new Date(protectedData?.endDate);
    const dateFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    const offerStartDateLabel = offerStartDate && (
      <FormattedDate value={offerStartDate} {...dateFormatOptions} />
    );
    const offerEndDateLabel = offerEndDate && (
      <FormattedDate value={offerEndDate} {...dateFormatOptions} />
    );

    if (offerAmount && currentListing && currentTransaction) {
      currentListing.attributes.price = new Money(Number(offerAmount) * 100, config.currency);
      currentTransaction.listing.attributes.price = new Money(
        Number(offerAmount) * 100,
        config.currency
      );
    }

    const lastTransition = currentTransaction?.attributes?.lastTransition;
    const transitions = currentTransaction?.attributes?.transitions || [];

    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const isOfferMade =
      lastTransition === TRANSITION_MAKE_OFFER ||
      lastTransition === TRANSITION_MAKE_OFFER_AFTER_REQUEST ||
      lastTransition === TRANSITION_ENQUIRE_WITH_ACCEPTED_OFFER;
    const isOfferAccepted =
      lastTransition === TRANSITION_ACCEPT_OFFER ||
      lastTransition === TRANSITION_ENQUIRE_WITH_ACCEPTED_OFFER;
    const isOfferDeclined = lastTransition === TRANSITION_DECLINE_OFFER;

    const txJustAccepted = lastTransition === TRANSITION_ACCEPT;

    const bothUsersSigned = lastTransition === TRANSITION_SIGNED_BY_PROVIDER;

    const claimRequested = lastTransition === TRANSITION_REQUEST_CLAIM_BY_PROVIDER;
    const claimAmount = protectedData?.claimData?.amount;
    const claimDetails = protectedData?.claimData?.details;
    const claimAccepted = transitions.find(
      t => t.transition === TRANSITION_ACCEPT_CLAIM_BY_OPERATOR
    );
    const claimDeclined = transitions.find(
      t => t.transition === TRANSITION_DECLINE_CLAIM_BY_OPERATOR
    );

    const isPastEndDate =
      new Date() >
      new Date(
        currentTransaction?.booking?.attributes?.displayStart ||
          currentTransaction?.booking?.attributes?.start
      );

    const hideBreakdownMaybe = isOfferMade || isOfferAccepted;
    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel:
            isCustomer && !isProviderBanned && hasCorrectNextTransition && isOfferAccepted,
          showPendingOfferMessage: isOfferMade && !isOfferAccepted,
          showOfferActionButtons: isOfferMade && isCustomer && !isOfferAccepted,
          showMakeOfferButton: isProvider && !isOfferMade && !isOfferAccepted,
          hideBreakdownMaybe,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
          showMakeOfferButton: isProvider && !isOfferMade && !isOfferAccepted,
          //TODO uncomment when second payment intent problem is solved
          isPreauthorized: true,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showClaimButton: isProvider && bothUsersSigned && (showClaim || isPastEndDate),
          claimRequested: claimRequested && claimAmount && claimDetails,
          claimAccepted,
          claimDeclined,
        };
      } else if (txIsWaitingForCustomerSignature(tx)) {
        return {
          headingState: HEADING_WAITING_FOR_CUSTOMER_SIGNATURE,
          showDetailCardHeadings: true,
          showAddress: true,
          //showSignButton: isCustomer,
          showSendContract: isCustomer,
        };
      } else if (txIsWaitingForProviderSignature(tx)) {
        return {
          headingState: HEADING_WAITING_FOR_PROVIDER_SIGNATURE,
          showDetailCardHeadings: true,
          showAddress: true,
          showSignButton: isProvider,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={async () => {
          await onAcceptSale(currentTransaction.id);
          const otherUser = getOtherUser(currentUser, transaction?.provider, transaction?.customer);
          await sendSmsNotification({
            sender: currentUser,
            receiver: otherUser,
            transactionId: transaction?.id?.uuid,
            type: 'BOOKING_REQUEST_ACCEPTED',
            receiverRole: transactionRole === 'customer' ? 'provider' : 'customer',
          }).catch(e => {
            return resp;
          });
        }}
        onDeclineSale={async () => {
          await onDeclineSale(currentTransaction.id);

          const otherUser = getOtherUser(currentUser, transaction?.provider, transaction?.customer);
          await sendSmsNotification({
            sender: currentUser,
            receiver: otherUser,
            transactionId: transaction?.id?.uuid,
            type: 'BOOKING_REQUEST_DECLINED',
            receiverRole: transactionRole === 'customer' ? 'provider' : 'customer',
          }).catch(e => {
            return resp;
          });
        }}
      />
    );

    const signButton = (
      <PrimaryButton
        className={css.signButton}
        type="button"
        onClick={() => this.setSignatureModalOpen(true)}
      >
        <FormattedMessage id="TransactionPanel.signButton" />
      </PrimaryButton>
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const handleSignContract = () => {
      const metadataInfo = isCustomer ? {} : {};
      return onSignContract(transactionRole, currentTransaction, metadataInfo);
    };

    const handleSendContract = () => {
      this.setState({
        loading: true,
      });
      const body = {
        ownerId: currentProvider.id.uuid,
        driverEmail: currentUser.attributes.email,
        title: listingTitle,
        txId: currentTransaction.id.uuid,
      };
      return post('/api/send-adobe-contract', body)
        .then(resp => {
          this.setState({
            contractSent: true,
            loading: false,
          });
        })
        .catch(e => {
          this.setState({
            loading: false,
          });
          console.log(e);
        });
    };

    const classes = classNames(rootClassName || css.root, className);

    const transitionsAllowingCancel = [
      // 'transition/accept',
      // 'transition/confirm-payment',
      // 'transition/waiting-for-customer-signature',
      // 'transition/signed-by-customer',
      // 'transition/signed-by-provider',
      'transition/confirm-payment-after-offer',
      'transition/signed-by-provider',
    ];
    const showCancelButtton = !isProvider && transitionsAllowingCancel.includes(lastTransition);

    // offer logic

    const pendingOfferMessage = isCustomer ? (
      <span>
        {`Do you accept the offer for $${offerAmount} per night from `}
        {offerStartDateLabel}
        {' to '}
        {offerEndDateLabel}
      </span>
    ) : (
      <span>
        {`Waiting for the customer to accept or decline your offer for $${offerAmount} per night from `}
        {offerStartDateLabel}
        {' to '}
        {offerEndDateLabel}
      </span>
    );

    const offerAcceptedMessage = isCustomer ? `You accepted the offer for $${offerAmount}` : null;

    const sendQuoteButton = stateData?.showMakeOfferButton && (
      <MakeOfferModal
        onMakeOffer={async submitData => {
          await onMakeOffer(currentTransaction?.id?.uuid, submitData);
          const otherUser = getOtherUser(currentUser, transaction?.provider, transaction?.customer);

          await sendSmsNotification({
            sender: currentUser,
            receiver: otherUser,
            transactionId: transaction?.id?.uuid,
            type: 'QUOTE_SENT_BY_PROVIDER',
            receiverRole: transactionRole === 'customer' ? 'provider' : 'customer',
          }).catch(e => {
            return resp;
          });
        }}
        onFetchQuoteLineItems={onFetchQuoteLineItems}
        quoteLineItems={quoteLineItems}
        listing={currentListing}
        fetchQuoteLineItemsInProgress={fetchQuoteLineItemsInProgress}
        fetchQuoteLineItemsError={fetchQuoteLineItemsError}
        timeSlots={timeSlots}
        isPreauthorized={stateData.isPreauthorized}
      />
    );

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />

            {stateData.showSendContract && !this.state.contractSent && (
              <div className={css.sendContractWrapper}>
                <div
                  className={
                    this.state.loading ? css.sendContractButtonDisabled : css.sendContractButton
                  }
                  onClick={this.state.loading ? () => {} : handleSendContract}
                >
                  {this.state.loading ? (
                    <FormattedMessage id="TransactionPanel.loadingMessage" />
                  ) : (
                    <>
                      <FormattedMessage id="TransactionPanel.sendContractButton" />{' '}
                      <ArticleIcon style={{ marginLeft: '10px' }} />
                    </>
                  )}
                </div>
              </div>
            )}

            {stateData.showSendContract && this.state.contractSent && (
              <div className={css.sendContractWrapper}>
                <center className={css.sendContractTextWrapper}>
                  <FormattedMessage
                    id="TransactionPanel.contractSentMessage"
                    values={{ driverEmail: currentUser.attributes.email }}
                  />
                </center>
              </div>
            )}

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe transaction={currentTransaction} transactionRole={transactionRole} />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}

            {sendQuoteButton}

            <div className={css.mobileSectionWrapper}>
              {stateData.showPendingOfferMessage && (
                <center>
                  <p className={css.pendingOfferMessage}>{pendingOfferMessage}</p>
                </center>
              )}

              {stateData.offerAccepted && (
                <center>
                  <p className={css.acceptedOfferMessage}>{offerAcceptedMessage}</p>
                </center>
              )}

              {stateData.showOfferActionButtons && (
                <div className={css.mobileActionButtons}>
                  <PrimaryButton onClick={() => onAcceptOffer(currentTransaction)}>
                    Accept offer
                  </PrimaryButton>
                  <SecondaryButton onClick={() => onDeclineOffer(currentTransaction)}>
                    Decline offer
                  </SecondaryButton>
                </div>
              )}
            </div>
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />

            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {stateData.showBookingPanel && (
                <BookingPanel
                  offerStartDate={offerStartDate}
                  offerEndDate={offerEndDate}
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  offerAmount={offerAmount}
                  addons={protectedData?.addons}
                />
              )}
              {!stateData.hideBreakdownMaybe && (
                <BreakdownMaybe
                  className={css.breakdownContainer}
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                />
              )}

              {showCancelButtton && (
                <div className={css.submitContainer} style={{ margin: 30 }}>
                  <PrimaryButton
                    rootClassName={css.submitButton}
                    onClick={() => onCancelSale(currentTransaction.id)}
                  >
                    Cancel Reservation
                  </PrimaryButton>
                </div>
              )}

              {/* CLAIM FEATURE */}

              {stateData.showClaimButton && (
                <MakeClaimModal currentTransaction={currentTransaction} onMakeClaim={onMakeClaim} />
              )}

              {stateData.claimRequested && (
                <p className={css.claimRequestInfo}>
                  A claim was made for ${claimAmount}
                  <br />
                  Details: {claimDetails}
                </p>
              )}

              {(stateData.claimAccepted || stateData.claimDeclined) && (
                <p className={css.claimRequestInfo}>
                  The claim for ${claimAmount} was{' '}
                  {stateData.claimAccepted ? 'accepted' : 'declined'}
                  <br />
                  Details: {claimDetails}
                </p>
              )}

              {/* ---- */}
              <div className={css.desktopSectionWrapper}>
                {stateData.showPendingOfferMessage && (
                  <center>
                    <p className={css.pendingOfferMessage}>{pendingOfferMessage}</p>
                  </center>
                )}

                {stateData.offerAccepted && (
                  <center>
                    <p className={css.acceptedOfferMessage}>{offerAcceptedMessage}</p>
                  </center>
                )}

                {stateData.showOfferActionButtons && (
                  <div className={css.desktopActionButtons}>
                    <PrimaryButton onClick={() => onAcceptOffer(currentTransaction)}>
                      Accept offer
                    </PrimaryButton>
                    <SecondaryButton onClick={() => onDeclineOffer(currentTransaction)}>
                      Decline offer
                    </SecondaryButton>
                  </div>
                )}
              </div>

              {stateData.showSaleButtons && (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              )}

              {/* {stateData.showSignButton && (
                <div className={css.desktopActionButtons}>{signButton}</div>
              )} */}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />

        <Modal
          isOpen={this.state.signatureModalOpen}
          onClose={() => {
            this.setSignatureModalOpen(false);
          }}
          onManageDisableScrolling={() => {}}
        >
          <div className={css.signatureModalContent}>
            <PrimaryButton type="button" onClick={handleSignContract}>
              <FormattedMessage id="TransactionPanel.signButton" />
            </PrimaryButton>
          </div>
        </Modal>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onSignContract: func.isRequired,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
