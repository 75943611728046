import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldCheckboxGroup } from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import css from './EditListingPoliciesForm.module.css';
import { composeValidators, required } from '../../util/validators';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        form: {
          mutators: { push },
        },
        values,
      } = formRenderProps;

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            label={'Sand dunes allowed?'}
            twoColumns={true}
            radio={true}
            className={css.checkboxGroup}
            id={'dunesAllowed'}
            name={'dunesAllowed'}
            options={[
              {
                key: 'yes',
                label: 'Yes',
              },
              {
                key: 'no',
                label: 'No',
              },
            ]}
          />

          <FieldCheckboxGroup
            label={'Rock crawling allowed?'}
            twoColumns={true}
            radio={true}
            className={css.checkboxGroup}
            id={'rockCrowlingAllowed'}
            name={'rockCrowlingAllowed'}
            options={[
              {
                key: 'yes',
                label: 'Yes',
              },
              {
                key: 'no',
                label: 'No',
              },
            ]}
          />

          <FieldCheckboxGroup
            label={'Do you allow instabook?'}
            twoColumns={true}
            radio={true}
            className={css.checkboxGroup}
            id={'instabookAllowed'}
            name={'instabookAllowed'}
            options={[
              {
                key: 'yes',
                label: 'Yes',
              },
              {
                key: 'no',
                label: 'No',
              },
            ]}
          />

          <FieldTextInput
            id="cleaningFee"
            name="cleaningFee"
            className={css.title}
            type="number"
            label={'Cleaning Fee (charged if brought back dirty)'}
            placeholder={'10'}
            validate={composeValidators(required('You need to complete this field'))}
          />

          <FieldTextInput
            id="minNight"
            name="minNight"
            className={css.title}
            type="number"
            label={'Minimum nights in order to book'}
            placeholder={'1'}
            validate={composeValidators(required('You need to complete this field'))}
          />

          <h2>Multi day discounts</h2>

          <div className={css.dynamicFieldsLabel}>
            <span>Add discount</span>
            <AddIcon
              className={css.addButton}
              onClick={() => push('multiDayDiscounts', undefined)}
            />
          </div>

          <div className={css.dynamicFieldsWrapper}>
            <FieldArray name="multiDayDiscounts">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div className={css.dynamicFieldWrapper} key={name}>
                    <div className={css.closeIconWrapper}>
                      <CloseIcon
                        onClick={() => fields.remove(index)}
                        style={{ color: '#827D8D', cursor: 'pointer' }}
                      />
                    </div>
                    <label className={css.dynamicItemLabel}>
                      {'Discount '}
                      {index + 1}
                    </label>

                    <FieldTextInput
                      id={`${name}.minNights`}
                      name={`${name}.minNights`}
                      className={css.title}
                      type="number"
                      label={'Minimum nights number'}
                      placeholder={'1'}
                      min={1}
                      max={99}
                      validate={composeValidators(required('You need to complete this field'))}
                    />

                    <FieldTextInput
                      id={`${name}.maxNights`}
                      name={`${name}.maxNights`}
                      className={css.title}
                      type="number"
                      label={'Maximum nights number'}
                      placeholder={'10'}
                      min={1}
                      max={99}
                      validate={composeValidators(required('You need to complete this field'))}
                    />

                    <FieldTextInput
                      id={`${name}.pricePerNight`}
                      name={`${name}.pricePerNight`}
                      className={css.title}
                      type="number"
                      label={'Price per night in USD'}
                      placeholder={'100'}
                      min={1}
                      validate={composeValidators(required('You need to complete this field'))}
                    />
                  </div>
                ))
              }
            </FieldArray>
          </div>

          <FieldTextInput
            id={`mileageLimit`}
            name={`mileageLimit`}
            className={css.title}
            type="number"
            label={'Mileage limit per night'}
            placeholder={'50'}
            min={1}
            validate={composeValidators(required('You need to complete this field'))}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
