import { post } from './api';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const rootUrl = typeof window !== 'undefined' && window.location.origin;

export const sendSmsNotification = async params => {
  try {
    const { sender, receiver, transactionId, type, receiverRole } = params;

    const receiverData = await sdk.users.show({ id: receiver.id });
    const receiverUserPhoneNumber =
      receiverData.data.data.attributes.profile?.publicData?.phoneNumber;
    const receiverUserFirstName = receiverData.data.data.attributes.profile.displayName;

    const orderOrSale = receiverRole === 'customer' ? 'order' : 'sale';

    if (!receiverUserPhoneNumber) {
      return 'Receiver does not have any phone number saved';
    }

    if (type === 'NEW_MESSAGE') {
      //DONE
      const message = `Hi, ${receiverUserFirstName}. You have a new message on Side By Side Share. Click here to view the message: ${rootUrl ||
        'https://sidebyside-dev.herokuapp.com'}/${orderOrSale}/${transactionId}/details `;
      return post('/api/send-sms-light', {
        phoneNumber: receiverUserPhoneNumber,
        message,
      }).catch(e => {
        console.log(e);
        return 'Some error occured while trying to send SMS notification';
      });
    }

    if (type === 'ENQUIRY') {
      //DONE
      const message = `Hi, ${receiverUserFirstName}. You have a rental inquiry on Side By Side Share. Click here to view and respond: ${rootUrl ||
        'https://sidebyside-dev.herokuapp.com'}/sale/${transactionId}/details `;
      return post('/api/send-sms-light', {
        phoneNumber: receiverUserPhoneNumber,
        message,
      }).catch(e => {
        console.log(e);
        return 'Some error occured while trying to send SMS notification';
      });
    }

    if (type === 'NEW_BOOKING_REQUEST') {
      //DONE
      const message = `Hi, ${receiverUserFirstName}. You have a new booking request in Side by Side Share. Click here to view the request: ${rootUrl ||
        'https://sidebyside-dev.herokuapp.com'}/sale/${transactionId}/details `;
      return post('/api/send-sms-light', {
        phoneNumber: receiverUserPhoneNumber,
        message,
      }).catch(e => {
        console.log(e);
        return 'Some error occured while trying to send SMS notification';
      });
    }

    if (type === 'BOOKING_REQUEST_DECLINED') {
      //DONE
      const message = `Hi, ${receiverUserFirstName}. Your booking request in Side By Side Share has been declined. Click here to view: ${rootUrl ||
        'https://sidebyside-dev.herokuapp.com'}/order/${transactionId}/details `;
      return post('/api/send-sms-light', {
        phoneNumber: receiverUserPhoneNumber,
        message,
      }).catch(e => {
        console.log(e);
        return 'Some error occured while trying to send SMS notification';
      });
    }

    if (type === 'BOOKING_REQUEST_ACCEPTED') {
      //DONE
      const message = `Hi, ${receiverUserFirstName}. Your booking request was accepted! Watch our 2 minute safety video (https://youtu.be/mdlh6DTauMQ), and click here to view the booking and sign the rental agreement: ${rootUrl ||
        'https://sidebyside-dev.herokuapp.com'}/order/${transactionId}/details `;
      return post('/api/send-sms-light', {
        phoneNumber: receiverUserPhoneNumber,
        message,
      }).catch(e => {
        console.log(e);
        return 'Some error occured while trying to send SMS notification';
      });
    }

    if (type === 'QUOTE_SENT_BY_PROVIDER') {
      //DONE
      const senderData = await sdk.users.show({ id: sender.id });
      const senderUserFirstName = senderData.data.data.attributes.profile.displayName;

      const message = `
        Hi, ${receiverUserFirstName}. ${senderUserFirstName} has sent you a new quote, click here to accept or reject the quote: ${rootUrl ||
        'https://sidebyside-dev.herokuapp.com'}/order/${transactionId}/details `;

      return post('/api/send-sms-light', {
        phoneNumber: receiverUserPhoneNumber,
        message,
      }).catch(e => {
        console.log(e);
        return 'Some error occured while trying to send SMS notification';
      });
    }

    return 'Unknown notification type';
  } catch (e) {
    console.log(e);
  }
};
