import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import { IconEmailAttention, InlineTextButton, NamedLink } from '../../components';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import css from './ModalMissingInformation.module.css';

const PhoneNumberReminder = props => {
  const {
    className,
    user,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
  } = props;

  const email = user.id ? <span className={css.email}>{user.attributes.email}</span> : '';

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.helperLink} onClick={onResendVerificationEmail}>
      <FormattedMessage id="ModalMissingInformation.resendEmailLinkText" />
    </InlineTextButton>
  );

  const fixEmailLink = (
    <NamedLink className={css.helperLink} name="ContactDetailsPage">
      <FormattedMessage id="ModalMissingInformation.fixEmailLinkText" />
    </NamedLink>
  );

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'ModalMissingInformation.resendFailedTooManyRequests'
    : 'ModalMissingInformation.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  return (
    <div className={className}>
      <PermPhoneMsgIcon className={css.phoneIcon} />
      <p className={css.modalTitle}>Please verify your phone number</p>
      <p className={css.modalMessage}>
        We are currently unable to send you SMS notifications if other Side By Side Share users
        contact you because you haven't verified your phone number.
      </p>

      <p>
        Verify your phone number in <NamedLink name="ContactDetailsPage">here.</NamedLink>
      </p>
    </div>
  );
};

export default PhoneNumberReminder;
