export const includedOptions = [
  {
    key: 'comesWithTrailer',
    label: 'Comes with trailer',
  },
  {
    key: 'helmets',
    label: 'Helmets',
  },
  {
    key: 'gasCan',
    label: 'Gas can',
  },
  {
    key: 'speakerSystem',
    label: 'Speaker System',
  },
  {
    key: '8PinConnector',
    label: '8 pin connector',
  },
  {
    key: 'windshield',
    label: 'Windshied',
  },
  {
    key: '4pinConnector',
    label: '4 pin connector',
  },
  {
    key: 'googles',
    label: 'Googles',
  },
];

export const addonsTypeOptions = [
  { key: 'fixed', label: 'Fixed' },
  { key: 'daily', label: 'Daily' },
  { key: 'perMile', label: 'Per mile' },
];
