import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckboxGroup, FieldSelect } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import arrayMutators from 'final-form-arrays';
import css from './EditListingDescriptionForm.module.css';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const vehicleMakeOptions = findOptionsForSelectFilter('vehicleMake', config.custom.filters);
      let minCashValue = 1000;
      let maxCashValue = 12000;

      if (values?.category === '4wheeler') {
        minCashValue = 1000;
        maxCashValue = 12000;
      }
      if (values?.category === 'sideBySide') {
        minCashValue = 5000;
        maxCashValue = 40000;
      }
      console.log(values.category);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            categories={categories}
            intl={intl}
          />

          <FieldTextInput
            id="vin"
            name="vin"
            className={css.title}
            type="text"
            label={'VIN number (this info will not be visible to renters)'}
            placeholder={'VIN number'}
            validate={composeValidators(required('You need to complete this field'))}
          />

          <FieldTextInput
            id="cashValueOfRental"
            name="cashValueOfRental"
            className={css.title}
            type="number"
            label={'What is the cash Value of your rental'}
            placeholder={'5000'}
            min={minCashValue}
            max={maxCashValue}
            validate={composeValidators(required('You need to complete this field'))}
          />

          <FieldTextInput
            id="modelYear"
            name="modelYear"
            className={css.title}
            type="number"
            label={'Model Year'}
            placeholder={'2010'}
            min={2000}
            max={2025}
            validate={composeValidators(required('You need to complete this field'))}
          />

          <FieldSelect
            className={css.category}
            name={'vehicleMake'}
            id={'vehicleMake'}
            label={'Vehicle make'}
            validate={composeValidators(required('You need to select an option'))}
          >
            <option disabled value="">
              {'Select an option'}
            </option>
            {vehicleMakeOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <FieldTextInput
            id="model"
            name="model"
            className={css.title}
            type="text"
            label={'Vehicle model'}
            placeholder={'Model'}
            validate={composeValidators(required('You need to complete this field'))}
          />

          {/* END OF FIELDS */}

          {/* <FieldCheckboxGroup
            label={'Comes with Trailer'}
            twoColumns={true}
            radio={true}
            className={css.checkboxGroup}
            id={'trailer'}
            name={'trailer'}
            options={[
              {
                key: 'yes',
                label: 'Yes',
              },
              {
                key: 'no',
                label: 'No',
              },
            ]}
          /> */}

          {/* <FieldCheckboxGroup
            label={'Windshield'}
            twoColumns={true}
            radio={true}
            className={css.checkboxGroup}
            id={'windshield'}
            name={'windshield'}
            options={[
              {
                key: 'yes',
                label: 'Yes',
              },
              {
                key: 'no',
                label: 'No',
              },
            ]}
          />
          <FieldCheckboxGroup
            label={'Speaker system'}
            twoColumns={true}
            radio={true}
            className={css.checkboxGroup}
            id={'speakerSystem'}
            name={'speakerSystem'}
            options={[
              {
                key: 'yes',
                label: 'Yes',
              },
              {
                key: 'no',
                label: 'No',
              },
            ]}
          /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
