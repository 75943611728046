import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import './UpsellingItemsField.module.css';
import { FieldCheckboxGroup, FieldCurrencyInput, FieldSelect } from '../../../components';
import config from '../../../config';
import css from './UpsellingItemsField.module.css';
import { goToBottomSmoothly } from '../utils';

const UpsellingItemsField = props => {
  const { push, form, values, intl } = props;
  return (
    <div className={css.wrapper}>
      <div className={css.dynamicFieldsLabel}>
        <p className={css.title}>{'Create new add on'}</p>
        <div className={css.addButton}>
          <AddIcon
            className={css.addButtonIcon}
            onClick={() => {
              push('upsellingItems', undefined);
              goToBottomSmoothly();
            }}
          />
        </div>
      </div>

      {values.upsellingItems && values.upsellingItems?.length > 0 && (
        <div className={css.dynamicFieldsWrapper}>
          <FieldArray name="upsellingItems">
            {({ fields }) =>
              fields.map((name, index) => {
                const upsellingItems = values.upsellingItems;
                const currentUpsellingItem = upsellingItems[index];

                return (
                  <div className={css.dynamicFieldWrapper} key={name}>
                    <div className={css.closeIconWrapper}>
                      <CloseIcon
                        onClick={() => fields.remove(index)}
                        style={{ color: '#827D8D', cursor: 'pointer' }}
                      />
                    </div>
                    <label className={css.dynamicItemLabel}>
                      <span>{'Add on name'}</span>
                    </label>
                    <Field
                      name={`${name}.label`}
                      className={css.dynamicField}
                      component="input"
                      placeholder={'Type here...'}
                    />

                    {/* <label className={css.dynamicItemLabel}>
                      <span>
                        {intl.formatMessage({
                          id: 'UpsellingField.type',
                        })}
                      </span>
                    </label>
                    <FieldSelect
                      id={`${name}.type`}
                      name={`${name}.type`}
                      className={css.selectField}
                    >
                      <option value="" disabled hidden>
                        {intl.formatMessage({
                          id: 'UpsellingField.selectAnOption',
                        })}
                      </option>
                      <option value={'perNight'}>
                        {intl.formatMessage({
                          id: 'UpsellingField.perNight',
                        })}
                      </option>
                      <option value={'perPiece'}>
                        {intl.formatMessage({
                          id: 'UpsellingField.perPiece',
                        })}
                      </option>
                      <option value={'perNightOrPiece'}>
                        {intl.formatMessage({
                          id: 'UpsellingField.perNightOrPiece',
                        })}
                      </option>
                    </FieldSelect> */}

                    <label className={css.dynamicItemLabel}>
                      <span>{'Price'}</span>
                    </label>
                    <FieldCurrencyInput
                      id={`${name}.priceAmount`}
                      name={`${name}.priceAmount`}
                      className={css.priceInput}
                      placeholder={'Type here'}
                      currencyConfig={config.currencyConfig}
                    />

                    <br />
                    <FieldCheckboxGroup
                      className={css.features}
                      id={`${name}.type`}
                      name={`${name}.type`}
                      threeColumns={true}
                      options={[
                        { key: 'fixed', label: 'Fixed' },
                        { key: 'daily', label: 'Daily' },
                        { key: 'perMile', label: 'Per mile' },
                      ]}
                      radio={true}
                    />
                  </div>
                );
              })
            }
          </FieldArray>
        </div>
      )}
    </div>
  );
};

export default UpsellingItemsField;
