import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { post } from '../../util/api';
import { loadStripe } from '@stripe/stripe-js';


import css from './TopbarDesktop.module.css';


const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});


class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ stripe: await this.props.stripePromise });
  }

  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    console.log('currentUser start:', this.props.currentUser);
  

    // TODO big case when came after login this is null??
    if (typeof window !== 'undefined') {
      if (this.props.currentUser === null || this.props.currentUser === undefined) {
        sessionStorage.removeItem('submitted');
        window.location.href = '/login';
        return;
      }
    }

    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('submitted', true);
    }

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    // Call your backend to create the VerificationSession.
    const response = await post('/api/create-verification-session', {
      id: this.props.currentUser.id.uuid,
    });

    console.log(response);
    const session = response; // await response.json();

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(session.client_secret);

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('Verification submitted!');
    }
  }

  render() {
    const { stripe } = this.state;
    return (
      // <button role="link" disabled={!stripe} onClick={this.handleClick}>
      //   Verify
      // </button>
      
      <a href="#" className={css.createListingLink} 
        onClick={this.handleClick}
      ><span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
        </span></a>
    );
  }
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;


  const [currentUser1, setCurrentUser1] = useState([]);
  const [id, setId] = useState('');
  const [verified, setVerified] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let submitted = false;
  console.log(submitted);

  useEffect(() => {
    async function fetchData() {
      const user = await sdk.currentUser.show();
      setCurrentUser1(user.data.data);
      setId(user.data.data.id.uuid);
      setVerified(user.data.data.attributes.profile.publicData.isVerified);
    }


    if ((currentUser1 != null && currentUser1.length>0) && window.sessionStorage.getItem('submitted')) {
      fetchData();
    }

    //if (typeof veriff !== 'undefined') {
    //  window.location.reload();
    //}
  }, []);

  const shuffle = useCallback(() => {
    let submitted = window.sessionStorage.getItem('submitted');
    //TODO don't loop anymore if already verified
    if (submitted && currentUser1 != null) {
      setIsLoading(true);
      return sdk.currentUser
        .show()
        .then(user => {
          console.log(user.data.data);
          setCurrentUser1(user.data.data);
          setId(user.data.data.id.uuid);
          setVerified(user.data.data.attributes.profile.publicData.isVerified);

          if (user.data.data.attributes.profile.publicData.isVerified) {
            sessionStorage.removeItem('submitted');
            window.location.href = '/l/new';
          }
          //if verified -> redirect
        })
        .catch(e => {

          console.log(e);
        }
          
          );
    }
  }, []);

  useEffect(() => {
    //if (submitted)
    //{
    const intervalID = setInterval(shuffle, 6500);
    return () => clearInterval(intervalID);
    //}
  }, [shuffle]);

  if (typeof window !== 'undefined') {
    window.onunload = () => {
      sessionStorage.removeItem('submitted');
    };
  }

  // prettier-ignore



  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}
      {/*
      <a href="https://google.ro" className={css.createListingLink} 
        onClick={(e)=> {
          e.preventDefault();
          alert('Please verify your identity first');
        }
      }><span className={css.createListing}>test</span></a>
      */}
      
      
      <VerifyButton 
      isAuthenticated={isAuthenticated}
      stripePromise={stripePromise} 
      currentUser={currentUser}
      key={currentUser ? currentUser.id : 'logged-out'} 
      id={id} /> 

{/*
      <NamedLink className={css.createListingLink} name="NewListingPage" 
      onClick={(e)=> {
        e.preventDefault();
        alert('Please verify your identity first');
      }}>
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink> */}

      {/* <NamedLink className={css.createListingLink} name="NewListingPage" 
        onClick={(e)=> {
          e.preventDefault();
          alert('Please verify your identity first');
        }}>
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink> */}
      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
