import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

export const getUpsellingItemsInitialValue = publicData => {
  const upsellingItemsFromPublicData = publicData?.upsellingItems;
  const convertedUpsellingItems =
    upsellingItemsFromPublicData &&
    upsellingItemsFromPublicData.map(u => {
      return {
        ...u,
        priceAmount: new Money(Number(u.priceAmount), config.currency),
      };
    });

  return convertedUpsellingItems || [];
};

export const convertUpsellingItemsForSubmit = upsellingItems => {
  const converted =
    upsellingItems &&
    upsellingItems.map(u => {
      return {
        ...u,
        priceAmount: u.priceAmount.amount,
      };
    });

  return converted || [];
};
