/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemDeposit = props => {
  const { transaction, isProvider, intl } = props;
  const label = 'Deposit';
  if (isProvider) {
    return null;
  }
  return (
    <div className={css.depositWrapper}>
      <div key={`deposit-item.code`} className={css.lineItem}>
        <span className={css.itemLabel}>{label}</span>
        <span className={css.totalPrice}>{'$1,000.00'}</span>
      </div>
      <p className={css.info}>
        *The security deposit is a hold on the card used for payment. The hold will be placed 24
        hours before your reservation start date and released after the rental is returned without
        damage.
      </p>
    </div>
  );
};

LineItemDeposit.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDeposit;
