/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';
import { post } from '../../util/api';
import { loadStripe } from '@stripe/stripe-js';

import css from './TopbarMobileMenu.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});



class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ stripe: await this.props.stripePromise });
  }

  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    console.log('currentUser start:', this.props.currentUser);
  

    // TODO big case when came after login this is null??
    if (typeof window !== 'undefined') {
      if (this.props.currentUser === null || this.props.currentUser === undefined) {
        sessionStorage.removeItem('submitted3');
        window.location.href = '/login';
        return;
      }
    }

    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('submitted3', true);
    }

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    // Call your backend to create the VerificationSession.
    const response = await post('/api/create-verification-session', {
      id: this.props.currentUser.id.uuid,
    });

    console.log(response);
    const session = response; // await response.json();

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(session.client_secret);

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('Verification submitted!');
    }
  }

  render() {
    const { stripe } = this.state;
    return (
      
      
      <a href="#" className={css.createNewListingLink} 
        onClick={this.handleClick}
      ><span className={css.createListing}>
        <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </span></a>

      // <NamedLink className={css.createNewListingLink} name="NewListingPage">
      //     <FormattedMessage id="TopbarMobileMenu.newListingLink" />
      //   </NamedLink>
    );
  }
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const [currentUser1, setCurrentUser1] = useState([]);
  const [id, setId] = useState('');
  const [verified, setVerified] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let submitted = false;

  useEffect(() => {
    async function fetchData() {
      const user = await sdk.currentUser.show();
      setCurrentUser1(user.data.data);
      setId(user.data.data.id.uuid);
      setVerified(user.data.data.attributes.profile.publicData.isVerified);
    }


    if ((currentUser1 != null && currentUser1.length>0) && window.sessionStorage.getItem('submitted3')) {
      fetchData();
    }

    //if (typeof veriff !== 'undefined') {
    //  window.location.reload();
    //}
  }, []);

  const shuffle = useCallback(() => {
    let submitted = window.sessionStorage.getItem('submitted3');
    //TODO don't loop anymore if already verified
    if (submitted && currentUser1 != null) {
      setIsLoading(true);
      return sdk.currentUser
        .show()
        .then(user => {
          console.log(user.data.data);
          setCurrentUser1(user.data.data);
          setId(user.data.data.id.uuid);
          setVerified(user.data.data.attributes.profile.publicData.isVerified);

          if (user.data.data.attributes.profile.publicData.isVerified) {
            sessionStorage.removeItem('submitted3');
            window.location.href = '/l/new';
          }
          //if verified -> redirect
        })
        .catch(e => {

          console.log(e);
        }
          
          );
    }
  }, []);

  useEffect(() => {
    //if (submitted)
    //{
    const intervalID = setInterval(shuffle, 6500);
    return () => clearInterval(intervalID);
    //}
  }, [shuffle]);

  if (typeof window !== 'undefined') {
    window.onunload = () => {
      sessionStorage.removeItem('submitted3');
    };
  }

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);




  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
      <div className={css.footer}>
        {/* <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink> */}

        <VerifyButton 
      isAuthenticated={isAuthenticated}
      stripePromise={stripePromise} 
      currentUser={currentUser}
      key={currentUser ? currentUser.id : 'logged-out'} 
      id={id} /> 
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
