import { addonsTypeOptions } from '../../util/general';

export const getAddonsOptions = listing => {
  let options = [];
  const upsellingItems = listing?.attributes?.publicData?.upsellingItems;
  if (upsellingItems && upsellingItems?.length > 0) {
    options = upsellingItems.map((item, index) => {
      const priceLabel = item?.priceAmount / 100;
      const typeLabel = addonsTypeOptions.find(i => i.key === item.type)?.label;
      const finalLabel = `${item.label} ($${priceLabel} / ${typeLabel})`;
      return {
        label: finalLabel,
        key: index,
      };
    });
  }

  return options;
};
