import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldDateRangeInput,
  FieldCheckboxGroup,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import arrayMutators from 'final-form-arrays';
import css from './BookingDatesForm.module.css';
import { getAddonsOptions } from './utils';
import { loadStripe } from '@stripe/stripe-js';
import { post } from '../../util/api';


const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const identity = v => v;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    //this.state = { focusedInput: null, fetchedLineItems: false };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);

    
    // Initial state
    this.state = {
      focusedInput: null,
      fetchedLineItems: false,
      stripe: null,
      currentUser1: [],
      id: '',
      verified: '',
      isLoading: false,
      intervalID: null
    };
    //this.handleClick = this.handleClick.bind(this);

    // bindings
    // Bindings
    //this.handleFormSubmit = this.handleFormSubmit.bind(this);
    //this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    //this.handleOnChange = this.handleOnChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.shuffle = this.shuffle.bind(this);
  }

  async componentDidMount() {
    // Set stripe
    const stripe = await stripePromise;
    this.setState({ stripe });

    // Fetch initial data
    this.fetchData();

    // Set interval for shuffle
    const intervalID = setInterval(this.shuffle, 6500);
    this.setState({ intervalID });

    if (typeof window !== 'undefined') {
      this.onUnload = this.onUnload.bind(this);
      window.addEventListener('beforeunload', this.onUnload);
    }
  }

  componentWillUnmount() {
    // Clear interval on component unmount
    clearInterval(this.state.intervalID);

    if (typeof window !== 'undefined') {
      window.removeEventListener('beforeunload', this.onUnload);
    }
  }

  onUnload(event) {
    sessionStorage.removeItem('submitted1');
  }

  async fetchData() {
    try {
      const user = await sdk.currentUser.show();
      this.setState({
        currentUser1: user.data.data,
        id: user.data.data.id.uuid,
        verified: user.data.data.attributes.profile.publicData.isVerified
      });
    } catch (e) {
      console.log(e);
    }
  }

  async shuffle() {
    let submitted = window.sessionStorage.getItem('submitted1');
    if (submitted) {
      this.setState({ isLoading: true });
      try {
        const user = await sdk.currentUser.show();
        console.log(user.data.data);
        this.setState({
          currentUser1: user.data.data,
          id: user.data.data.id.uuid,
          verified: user.data.data.attributes.profile.publicData.isVerified
        });

        if (user.data.data.attributes.profile.publicData.isVerified) {
          //window.location.href = '/l/new';
          sessionStorage.removeItem('submitted1');
          this.handleFormSubmit();
          
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  async handleClick(event) {

    if (typeof window !== 'undefined') {
      if (this.state.currentUser1 === null || this.state.currentUser1 === undefined || this.state.currentUser1.length === 0) {
        sessionStorage.removeItem('submitted1');
        window.location.href = '/login';
        return;
      }
    }

    if (this.state.currentUser1.attributes.profile.publicData.isVerified) {
      return;
    }
    

    // Block native event handling.
    event.preventDefault();

    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('submitted1', true);
    }

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    // Call your backend to create the VerificationSession.
    const response = await post('/api/create-verification-session', {
      id: this.state.id,
    });

    console.log(response);
    const session = response; // await response.json();

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(session.client_secret);

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('Verification submitted!');
    }
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  async handleFormSubmit(e) {

    if (e === undefined || e === null) {
      return;
    }

    // // Block native event handling.
    // e.preventDefault();

    // if (typeof window !== 'undefined') {
    //   window.sessionStorage.setItem('submitted', true);
    // }

    // const { stripe } = this.state;

    // if (!stripe) {
    //   // Stripe.js has not loaded yet. Make sure to disable
    //   // the button until Stripe.js has loaded.
    //   return;
    // }

    // // Call your backend to create the VerificationSession.
    // const response = await post('/api/create-verification-session', {
    //   id: this.props.id,
    // });

    // console.log(response);
    // const session = response; // await response.json();

    // // Show the verification modal.
    // const { error } = await stripe.verifyIdentity(session.client_secret);

    // if (error) {
    //   console.log('[error]', error);
    // } else {
    //   console.log('Verification submitted!');
    // }





    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};
    const { offerStartDate, offerEndDate } = this.props;
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    const offerAmount = this.props.offerAmount;
    const { addons } = formValues?.values || {};
    if (
      startDate &&
      endDate &&
      !this.props.fetchLineItemsInProgress &&
      !this.state.fetchedLineItems
    ) {
      if (offerStartDate && offerEndDate) {
        this.setState({ fetchedLineItems: true });
      }
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, offerAmount, addons },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        mutators={{ ...arrayMutators }}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            offerAmount,
            offerStartDate,
            offerEndDate,
            listing,
            //currentUser,
            //currentUser1,
          } = fieldRenderProps;
          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const isOffer = offerStartDate && offerEndDate;
          const addonsOptions = getAddonsOptions(listing);
          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              <FieldDateRangeInput
                className={isOffer ? css.hiddenBookingDates : css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots}
                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                )}
                disabled={fetchLineItemsInProgress}
              />
              {addonsOptions?.length > 0 && (
                <FieldCheckboxGroup
                  label={'Add ons'}
                  className={isOffer ? css.hiddenCheckboxGroup : css.checkboxGroup}
                  id={'addons'}
                  name={'addons'}
                  options={addonsOptions}
                />
              )}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                {/* {JSON.stringify(currentUser)} */}
                <PrimaryButton type="submit"
                  onClick={this.handleClick}
                  >
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
